export const CONST = {
  newsTabList: {
    listOne: [
      { title: '新闻动态', type: 1, link: 'announcement' },
      { title: '标准资讯', type: 2, link: 'announcement'  },
      { title: '通知公告', type: 3, link: 'announcement'  },
    ],
    listTwo: [
      { title: '政策法规', type: 4, link: 'announcement'  },
      { title: '标准公告', type: 5, link: 'announcement'  },
      { title: '标准公式', type: 6, link: 'announcement'  },
    ],
    listThree: [
      { title: '近期发布标准', type: '0', link: 'standardDynamics' },
      { title: '即将实施标准', type: 2, link: 'standardDynamics' },
      { title: '即将废止标准', type: 4, link: 'standardDynamics' },
    ],
  },

  typeList: [
    { label: '全部', value: 'ALL' },
    { label: '国家标准', value: '国家标准' },
    { label: '行业标准', value: '行业标准' },
    { label: '地方标准', value: '地方标准' },
    { label: '团体标准', value: '团体标准' },
    { label: '国际标准', value: '国际标准' },
    { label: '国外标准', value: '国外标准' },
    { label: '企业标准', value: '企业标准' },
    { label: '其他标准', value: '其他标准' },
  ],
  statusList: [
    { label: '全部', value: 'ALL' },
    { label: '现行', value: 'ST' },
    { label: '未现行', value: 'NST' },
    { label: '废止', value: 'WT' },
  ],

  propertiesList: [
    { label: '全部', value: 'ALL' },
    { label: '强制', value: '强制' },
    { label: '推荐', value: '推荐' },
    { label: '指导', value: '指导' },
  ],
  
  standardServiceList: [
    { title: '标准<br />时效性查新', link: '/standardTimelinessSearch', svg: 'file_check', bcolor: '#FFEDE7', scolor: '#FF8D66' },
    { title: '正版纸质<br />标准申请', link: '/applicationStandard', svg: 'book', bcolor: '#E8F2FD', scolor: '#5DAAFF' },
    { title: '标准化试点<br />示范项目', link: '/standardizationPilotAndProjects', svg: 'map_pin', bcolor: '#FDF9D4', scolor: '#CFAC32' },
    { title: '地方标准<br />制 (修) 订', link: '/localStandardFormulation', svg: 'edit', bcolor: '#D6FBF4', scolor: '#52BEAA' },
    { title: 'WTO<br />资讯', link: 'http://www.tbt-sps.gov.cn/', svg: 'chat', bcolor: '#E2EEF4', scolor: '#5297BA' },
    { title: '条码<br />服务', link: 'http://www.ancc.org.cn/', svg: 'barcode', bcolor: '#F7F2EE', scolor: '#CE8D59' },
  ],
  standardizedServicesList: [
    { title: '标准时效性查新', link: 'standardTimelinessSearch', svg: 'file_check', bcolor: '#FFEDE7', scolor: '#FF8D66', con: '依据各类大型标准信息数据库、标准组织的官方网站和期刊等多种权威信息渠道，对技术标准的时效性进行确认出具标准时效性查新报告。' },
    { title: '标准项目立项查新', link: 'standardProjectSearch', svg: 'folder_check', bcolor: '#D8CDF7', scolor: '#5B39D6', con: '对拟立项或修订标准相关联的国家标准、行业标准、地方标准进行查询，将标准技术内容和相关联标准进行比对，出具项目查新报告，为立项评审提供依据。' },
    { title: '地方标准制（修）订', link: 'localStandardFormulation', svg: 'edit', bcolor: '#D6FBF4', scolor: '#52BEAA', con: '根据《中华人民共和国标准化法》，为满足地方自然条件、风俗习惯等特殊技术要求，可以制定地方标准。' },
    { title: '标准化试点、示范项目', link: 'standardizationPilotAndProjects', svg: 'map_pin', bcolor: '#FDF9D4', scolor: '#CFAC32', con: '建设工业、农业、服务业及社会事业标准化试点、示范项目，充分发挥标准化对各行各业的规范、支撑和引领作用，助推我市高质量发展。' },
    { title: '正版纸质标准申请', link: 'applicationStandard', svg: 'book', bcolor: '#E8F2FD', scolor: '#5DAAFF', con: '通过平台申请纸质标准文档。' },
    { title: '满意度调查', link: 'satisfactionSurvey', svg: 'like', bcolor: '#FFE3E7', scolor: '#F53F56', con: '通过平台进行满意度调查。' },
  ],
  userTypeList: [
    { label: '我是单位用户', value: '1' },
    { label: '我是个人用户', value: '0' },
  ],
  commonType: {
    about: [
      { label: '单位简介', value: 'companyProfile', link: 'companyProfile' },
      { label: '联系我们', value: 'relation', link: 'relation' },
    ],
    keyOne: [
      { label: '标准时效性查新', value: 'standardTimelinessSearch', link: '/userCenter?type=standardTimeliness', btn: '申请标准时效性查新' },
    ],
    keyTwo: [
      { label: '标准项目立项查新', value: 'standardProjectSearch', link: '/userCenter?type=standardProject', btn: '申请标准项目立项查新' },
    ], 
    // keyThree: [
    //   { label: '地方标准制（修）订', value: 'localStandardFormulation', link: '/userCenter?type=1', btn: '线上地方标准制（修）订' },
    // ],
    // keyFour: [
    //   { label: '标准化试点、示范项目', value: 'standardizationPilotAndProjects', link: '/userCenter?type=2', btn: '线上申请' },
    // ],
    keyThree: [
        { label: '地方标准制（修）订', value: 'localStandardFormulation' },
      ],
      keyFour: [
        { label: '标准化试点、示范项目', value: 'standardizationPilotAndProjects'},
      ],
    keyFive: [
      { label: '正版纸质标准申请', value: 'applicationStandard', link: '/userCenter?type=applicationStandard', btn: '线上申请' },
    ],
    keySix: [
      { label: '满意度调查', value: 'satisfactionSurvey' },
    ],
    userCenter: [
      { label: '个人信息', value: 'UserPanel' },
      { label: '标准时效性查新', value: 'standardTimeliness', panel: 'ApplyPanel' },
      { label: '标准项目立项查新', value: 'standardProject', panel: 'ApplyPanel' },
      { label: '正版纸质标准申请', value: 'applicationStandard', panel: 'ApplyPanel' },
      { label: '地方标准制（修）订', value: '1', panel: 'Waiting' },
      { label: '标准化试点、示范项目', value: '2', panel: 'Waiting' },
      { label: '我收藏的标准', value: 'CollectPanel' },
      { label: '互动交流', value: 'InteractiveListPanel' },
      { label: '退出登录', value: 'loginOut', btn: 'outLogin' },
    ],
  },
  standardState: [
    { label: '现行', value: 'ST', color: '#00B42A' },
    { label: '未现行', value: 'NST', color: '#FF7D00' },
    { label: '废止', value: 'WT', color: '#F53F3F' },
  ], 
  articleTable: [
    { title: '标题', slotName: 'title', dataIndex: 'title' },
    { title: '内容来源', dataIndex: 'sourceName', width: 282, align: 'center' },
    { title: '发布日期', dataIndex: 'publishedDate', width: 132, align: 'center' },
  ],
  searchCenter: [
    { title: '标准编号 :', key: ['standardNumber'], place: '请输入标准编号', type: 'text', width: '322px',span: 1 },
    { title: '标准名称 :', key: ['standardName'], place: '请输入标准中文名称', type: 'text', width: '322px',span: 1 },
    { title: '发布日期 :', key: ['startReleaseDate', 'endReleaseDate'], key1: 'rangeValue', type: 'date', width: '345px',span: 1, class: 'change' },
    // { title: '标准序号 :', key: ['sort'], place: '请输入标准序号', type: 'text', width: '322px',span: 1 },
    { title: '标准分类 :', key: ['standardClassificationList'], place: '选择标准分类', list: 'typeList', type: 'select', width: '322px',span: 1 },
    { title: '标准状态 :', key: ['standardStateList'], place: '选择标准状态', list: 'statusList', type: 'select', width: '322px',span: 1 },
    { title: '实施日期 :', key: ['startImplementDate', 'endImplementDate'], key1: 'rangeValue1', type: 'date', width: '345px',span: 1, class: 'change' },
    { title: '标准性质 :', key: ['listProperties'], place: '选择标准性质', list: 'propertiesList', type: 'select', width: '322px',span: 1, class: 'no_b' },
  ],
  standardDynamics: [
    { title: '状态类型 :', key: ['sort'], type: 'radio', width: '428px',span: 1, list: 'listThree', class: 'change_2 no_b' },
    { title: '标准分类 :', key: ['standardClassificationList'], place: '选择标准分类', list: 'typeList', type: 'select', width: '322px',span: 1, class: 'no_b' },
  ],
  standardTable: [
    { title: '标准编号', slotName: 'title', dataIndex: 'standard_number', width: 252 },
    { title: '标准名称', slotName: 'title', dataIndex: 'chinese_name', },
    { title: '发布日期', dataIndex: 'release_date', width: 132, align: 'center' },
    { title: '实施日期', dataIndex: 'implement_date', width: 132, align: 'center' },
    { title: '状态', slotName: 'status', width: 82, align: 'center' },
    { title: '操作', slotName: 'option', width: 60, align: 'center' },
  ],
  
  collectTable: [
    { title: '标准编号', slotName: 'title', dataIndex: 'standard_number', width: 252 },
    { title: '标准名称', slotName: 'title', dataIndex: 'chineseName', },
    { title: '发布日期', dataIndex: 'releaseDate', width: 132, align: 'center' },
    { title: '实施日期', dataIndex: 'implementDate', width: 132, align: 'center' },
    { title: '状态', slotName: 'status', width: 82, align: 'center' },
    // { title: '收藏日期', dataIndex: 'logNameN', width: 132, align: 'center' },
    { title: '操作', slotName: 'option', width: 60 },
  ],
  submitRecordsTable: [
    { title: '标题', dataIndex: 'title' },
    { title: '内容', dataIndex: 'content', },
    { title: '提交时间', dataIndex: 'submitDate' },
    { title: '答复', dataIndex: 'answer' },
  ],
  applyTable: [
    { title: '用户', dataIndex: 'userName' },
    { title: '手机号码', dataIndex: 'phone', },
    { title: '电子邮箱', dataIndex: 'email' },
    { title: '提交时间', dataIndex: 'createDate' },
    { title: '上传文件', slotName: 'option' },
  ],
  commonFileTable: [
    { title: '序号', slotName: 'cell', width: 72, align: 'center' },
    { title: '标题', dataIndex: 'fileName', },
    { title: '操作', slotName: 'option', width: 100, align: 'center'  },
  ]
}