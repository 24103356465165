import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "standard_panel" }


export default /*@__PURE__*/_defineComponent({
  __name: 'PanelOne',
  props: {
    data: {},
    detail: {},
    first: {}
  },
  setup(__props: any) {

  let props = __props;
  

return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.data as any).line)
      ? (_openBlock(), _createBlock(_component_a_divider, { key: 0 }))
      : _createCommentVNode("", true),
    ((_ctx.data as any).type != 4)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
      'common_flex', 
        (_ctx.data as any).type == 2 ? 'flex2' : 
        (_ctx.data as any).type == 3 ? 'flex3' : ''
      ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.data as any).data, (item) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "panel_item",
              key: (item as any).key
            }, [
              _createElementVNode("span", null, _toDisplayString(!_ctx.first ? (item as any).title : ''), 1),
              _createElementVNode("span", null, _toDisplayString((_ctx.$props.detail as any)[(item as any).key] || '-'), 1)
            ])), [
              [_vShow, (_ctx.$props.detail as any)[(item as any).key] && (_ctx.$props.detail as any)[(item as any).key] != '-']
            ])
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})