import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "standardDetail" }
const _hoisted_2 = {
  key: 0,
  class: "panel_one"
}
const _hoisted_3 = {
  key: 1,
  class: "show_detail"
}

import { onMounted, ref, getCurrentInstance } from 'vue'
  import { CONST } from '@/types/const'
  import PanelOne from './components/PanelOne.vue';
import { useRoute, useRouter } from 'vue-router';

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      PanelOne
    }
  },
  __name: 'Detail',
  setup(__props) {

  
  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;
  const route = useRoute()
  const router = useRouter()

  let detail = ref(null)

  let data = ref([
    {
      type: 2,
      line: true,
      data: [
        { title: '标准组织', key: 'standardOrganizationRedundancy' },
        { title: '语种', key: 'textLanguage' },
        { title: 'CCS分类', key: 'ccsCodeRedundancy' },
        { title: 'ICS分类', key: 'icsCodeRedundancy' },
      ]
    },
    { 
      type: 3,
      line: true,
      data: [
        { title: '发布日期', key: 'releaseDate' },
        { title: '实施日期', key: 'implementDate' },
        { title: '废止日期', key: 'abolishDate' },
      ]
    },
    {
      type: 4,
      line: true,
      data: [
        { 
          type: 3, 
          key: 'beReplaceStandardRedundancy',
          data: [
            { title: '被替代标准', key: '1' },
            { title: '替代关系', key: '2' },
            { title: '作废日期', key: '3' },
          ]
        },
        { 
          type: 3, 
          key: 'replaceStandardRedundancy',
          data: [
            { title: '替代标准', key: '1' },
            { title: '替代关系', key: '2' },
            { title: '作废日期', key: '3' },
          ]
        },
        { 
          type: 2, 
          key: 'beAdoptStandardRedundancy',
          data: [
            { title: '被采用标准', key: '1' },
            { title: '采用关系', key: '2' },
          ]
        },
        { 
          type: 2, 
          key: 'no',
          data: [
            { title: '采用标准', key: '1' },
            { title: '采用关系', key: '2' },
          ]
        },
        { 
          type: 2, 
          key: 'citeStandardRedundancy',
          data: [
            { title: '引用标准', key: '1' },
            { title: '引用关系', key: '2' },
          ]
        },
        { 
          type: 1, 
          key: 'no',
          data: [
            { title: '修改记录', key: '1' },
          ]
        },
      ]
    },
    {
      type: 1,
      line: true,
      data: [
        { title: '标准摘要', key: 'summary' },
        { title: '发布单位', key: 'releaseUnit' },
        { title: '归口单位', key: 'underCentralizedUnit' },
        { title: '起草单位', key: 'draftingUnit' },
        { title: '起草人', key: 'draftor' },
        { title: '出处', key: 'source' },
        { title: '备案号', key: 'recordNumber' },
        { title: '简评', key: '2' },
        { title: '备注', key: '1' },
      ]
    }
  ])

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.StandardDetail({
      standardNumber: route.query.number
    }).then((res: any) => {
      if(res.data.code == 200) {
        let num = 0;
        res.data.data.no = [{ 1: '-', 2: '-' }]
        res.data.data.releaseDate = res.data.data.releaseDate ? res.data.data.releaseDate.split(' ')[0] : ''
        res.data.data.implementDate = res.data.data.implementDate ?  res.data.data.implementDate.split(' ')[0] : ''
        res.data.data.abolishDate = res.data.data.abolishDate ?  res.data.data.abolishDate.split(' ')[0] : ''
        if(res.data.data.beReplaceStandardRedundancy) res.data.data.beReplaceStandardRedundancy = eventData(res.data.data.beReplaceStandardRedundancy)
        else {
          res.data.data.beReplaceStandardRedundancy = [];
          num += 1;
        }
        if(res.data.data.replaceStandardRedundancy) res.data.data.replaceStandardRedundancy = eventData(res.data.data.replaceStandardRedundancy)
        else {
          res.data.data.replaceStandardRedundancy = [];
          num += 1;
        }
        if(res.data.data.beAdoptStandardRedundancy) res.data.data.beAdoptStandardRedundancy = eventData(res.data.data.beAdoptStandardRedundancy)
        else {
          res.data.data.beAdoptStandardRedundancy = [];
          num += 1;
        }
        if(res.data.data.citeStandardRedundancy) res.data.data.citeStandardRedundancy = eventData(res.data.data.citeStandardRedundancy)
        else {
          res.data.data.citeStandardRedundancy = [];
          num += 1;
        }
        if(num == 4) data.value.forEach(item => {
          if(item.type == 4) item.line = num == 4 ? false : true;
        })
        data.value.forEach(item => {
          if(item.type != 4) {
            let leng = 0
            item.data.forEach(line => {
              if(!res.data.data[line.key]) leng++;
            })
            if(leng == item.data.length) item.line = false;
          }
        })
        detail.value = res.data.data
      }
    })
  }

  function eventData(n: any) {
    let data = n.split(';');
    let list = [] as any;
    data.forEach((item: any) => {
      let l = item.split(',');
      list.push({ 1: l[0], 2: l[1], 3: '-' })
    })
    return list;
  }

  function showPdf() {
    globalProperties.$API.GetUserInfo().then((res: any) => {
      if(res.data.code == 200) {
        window.open(`/showPdf?number=${ route.query.number }`)
      }else {
        globalProperties.$message.warning('您还未登录或者登录已失效，请先登录再操作此按钮！')
      }
    })
  }



return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(detail))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, [
            _createTextVNode(_toDisplayString((_unref(detail) as any).standardNumber) + " ", 1),
            _createElementVNode("span", {
              style: _normalizeStyle({ background: _unref(CONST).standardState.filter( item => { return item.value == (_unref(detail) as any).standardState } )[0].color })
            }, _toDisplayString(_unref(CONST).standardState.filter( item => { return item.value == (_unref(detail) as any).standardState } )[0].label), 5)
          ]),
          _createElementVNode("h3", null, _toDisplayString((_unref(detail) as any).chineseName), 1),
          _createElementVNode("p", null, _toDisplayString((_unref(detail) as any).englishName), 1),
          ((_unref(detail) as any).attachmentType && (_unref(detail) as any).attachmentType != 'NONE')
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                onClick: showPdf
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("在线阅读")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(detail))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.type != 4)
                ? (_openBlock(), _createBlock(PanelOne, {
                    key: 0,
                    data: item,
                    detail: _unref(detail)
                  }, null, 8, ["data", "detail"]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (item.line)
                      ? (_openBlock(), _createBlock(_component_a_divider, { key: 0 }))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (line, index1) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index1 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(detail)[(line as any).key], (line1, index2) => {
                          return (_openBlock(), _createBlock(PanelOne, {
                            key: index2,
                            first: index2,
                            data: line,
                            detail: (line1 as any)
                          }, null, 8, ["first", "data", "detail"]))
                        }), 128))
                      ], 64))
                    }), 128))
                  ], 64))
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})